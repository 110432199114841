<template>
  <div class="wrapper">
    <Loader v-if="isLoading" />
    <SmbTitles is-show-title is-show-sub-title title="Branding" />
    <div class="branding-list-wrapper">
      <div class="branding-list">
        <div class="branding-list__item">
          <div class="branding-list__item-text">Logo:</div>
          <div
            class="branding-list__item-img small-img-container"
            :class="{
              active: currentBrandingItem === 'logo',
            }"
          >
            <span class="icon-edit-png" v-if="getCurrentSmbCustomer.brand_logo">
              <img
                src="@/assets/img/icons/edit.png"
                alt=""
                @click="
                  clickedOnEditIcon(getCurrentSmbCustomer.brand_logo, 0, 'logo')
                "
              />
            </span>
            <span
              v-if="getCurrentSmbCustomer.brand_logo"
              @click="deleteBrandingHandler('logo')"
              class="icon-remove-png"
              ><img src="@/assets/img/icons/remove.png" alt=""
            /></span>
            <img
              v-if="getCurrentSmbCustomer.brand_logo"
              :src="getCurrentSmbCustomer.brand_logo"
              :alt="getCurrentSmbCustomer.brand_logo"
            />
            <div
              class="uploader-container"
              @click="
                clickedOnEditIcon(getCurrentSmbCustomer.brand_logo, 0, 'logo')
              "
              v-else
            >
              <v-icon class="uploader-container__icon"
                >icon-upload-image</v-icon
              >
              <span class="uploader-container__text">Upload</span>
            </div>
          </div>
        </div>
        <div class="branding-list__item">
          <div class="branding-list__item-text">Brokerage:</div>
          <div
            class="branding-list__item-img small-img-container"
            :class="{
              active: currentBrandingItem === 'brokerage',
            }"
          >
            <span
              class="icon-edit-png"
              v-if="getCurrentSmbCustomer.brokerage_logo"
            >
              <img
                src="@/assets/img/icons/edit.png"
                alt=""
                @click="
                  clickedOnEditIcon(
                    getCurrentSmbCustomer.brokerage_logo,
                    0,
                    'brokerage'
                  )
                "
              />
            </span>
            <span
              v-if="getCurrentSmbCustomer.brokerage_logo"
              @click="deleteBrandingHandler('brokerage')"
              class="icon-remove-png"
              ><img src="@/assets/img/icons/remove.png" alt=""
            /></span>
            <img
              v-if="getCurrentSmbCustomer.brokerage_logo"
              :src="getCurrentSmbCustomer.brokerage_logo"
              :alt="getCurrentSmbCustomer.brokerage_logo"
            />
            <div
              @click="
                clickedOnEditIcon(
                  getCurrentSmbCustomer.brokerage_logo,
                  0,
                  'brokerage'
                )
              "
              class="uploader-container"
              v-else
            >
              <v-icon class="uploader-container__icon"
                >icon-upload-image</v-icon
              >
              <span class="uploader-container__text">Upload</span>
            </div>
          </div>
        </div>
        <div class="branding-list__item">
          <div class="branding-list__item-text">Headshot:</div>
          <div
            class="branding-list__item-img small-img-container"
            :class="{
              active: currentBrandingItem === 'headshot',
            }"
          >
            <span
              class="icon-edit-png"
              v-if="getCurrentSmbCustomer.brand_headshots.length"
            >
              <img
                src="@/assets/img/icons/edit.png"
                alt=""
                @click="
                  clickedOnEditIcon(
                    getCurrentSmbCustomer.brand_headshots.length
                      ? getCurrentSmbCustomer.brand_headshots[0]
                      : null,
                    0,
                    'headshots'
                  )
                "
              />
            </span>
            <span
              v-if="getCurrentSmbCustomer.brand_headshots.length"
              @click="deleteBrandingHandler('headshots')"
              class="icon-remove-png"
              ><img src="@/assets/img/icons/remove.png" alt=""
            /></span>
            <img
              v-if="getCurrentSmbCustomer.brand_headshots.length"
              :src="getCurrentSmbCustomer.brand_headshots[0]"
              :alt="getCurrentSmbCustomer.brand_headshots[0]"
            />
            <div
              v-else
              class="uploader-container"
              @click="
                clickedOnEditIcon(
                  getCurrentSmbCustomer.brand_headshots.length
                    ? getCurrentSmbCustomer.brand_headshots[0]
                    : null,
                  0,
                  'headshots'
                )
              "
            >
              <v-icon class="uploader-container__icon"
                >icon-upload-image</v-icon
              >
              <span class="uploader-container__text">Upload</span>
            </div>
          </div>
        </div>
      </div>
      <div class="business-assets__title">Business assets:</div>
      <div class="business-assets-list">
        <div
          class="business-assets-list__item"
          v-for="(
            businessAsset, businessAssetIndex
          ) in getCurrentSmbCustomer.business_assets"
          :key="businessAsset + Math.random()"
        >
          <div class="small-img-container">
            <span
              class="icon-edit-png"
              @click="
                clickedOnEditIcon(
                  businessAsset,
                  businessAssetIndex,
                  'businessAsset'
                )
              "
            >
              <img src="@/assets/img/icons/edit.png" alt="" />
            </span>
            <span
              class="icon-remove-png"
              @click="deleteBusinessAssetHandler(businessAssetIndex)"
              ><img src="@/assets/img/icons/remove.png" alt=""
            /></span>
            <img
              class="business-asset-img"
              :src="businessAsset"
              :alt="businessAsset"
            />
          </div>
          <div class="small-img-container__upload asset-uploader">
            <SmbFileUploader
              :elementId="`businessAsset-${businessAssetIndex}`"
              isHideInput
              custom-class=""
              prependIcon="icon-upload-image"
              acceptProp="image/png, image/jpeg, image/bmp"
              @filesWasUpdated="editBusinessAsset($event, businessAssetIndex)"
            />
            <span class="small-img-container__upload-text">Upload</span>
          </div>
        </div>
        <div
          class="uploader-container border"
          @click="emitClickOnUploaderByElementId('newBusinessUploader')"
        >
          <v-icon class="uploader-container__icon">icon-upload-image</v-icon>
          <span class="uploader-container__text">Upload</span>
        </div>
        <SmbFileUploader
          class="asset-uploader"
          elementId="newBusinessUploader"
          isHideInput
          prependIcon="icon-upload-image"
          acceptProp="image/png, image/jpeg, image/bmp"
          @filesWasUpdated="uploadNewBusinessFileHandler($event)"
        />
        <SmbFileUploader
          elementId="branding-uploader"
          isHideInput
          class="asset-uploader"
          prependIcon="icon-upload-image"
          acceptProp="image/png, image/jpeg, image/bmp"
          @filesWasUpdated="uploadBrandingItem($event)"
        />
      </div>
      <div class="footer-buttons">
        <SmbActionBtn
          class="arrow-button"
          isUseIcon
          iconName="mdi-arrow-left"
          width="20%"
          @click="$router.push({ name: 'profile-settings' })"
        />
        <SmbActionBtn
          width="80%"
          text="Done"
          emitName="done"
          @done="$router.push({ name: 'profile-settings' })"
        />
      </div>
      <ModalDialog
        :isShow="removeBgDialog.isShow"
        :title="removeBgDialog.title"
        :footerButtons="removeBgDialog.footerButtons"
        :isLoading="removeBgDialog.isLoading"
        :isClickOutside="false"
        :isShowSlotFooter="false"
        customClass="remove-bg-dialog"
        @close="makeDefaultDialogOptions"
        @cancelToRemoveBg="cancelToRemoveBgHandler"
        @successToRemoveBg="approveToRemoveBg"
        @cancelToSetImgWithoutBg="cancelToSetImgWithoutBgHandler"
        @acceptToSetImgWithoutBg="acceptToSetImgWithoutBgHandler"
      >
        <template v-slot:description v-if="removeBgDialog.isShowImgWithoutBg">
          <div class="img-wrapper">
            <img
              class="img-without-bg"
              :src="imgUrlWithoutBg"
              :alt="imgUrlWithoutBg"
            />
          </div>
        </template>
      </ModalDialog>
    </div>
  </div>
</template>
<script>
import SmbFileUploader from "@/components/smb-client/SmbFileUploader.vue";
import { mapActions, mapGetters } from "vuex";
import uploader from "@/mixins/uploader";
import Loader from "@/components/global/Loader.vue";
import ModalDialog from "@/components/global/ModalDialog.vue";
import SmbTitles from "@/components/smb-client/SmbTitles.vue";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn.vue";

export default {
  name: "Index",
  components: { SmbActionBtn, SmbTitles, ModalDialog, Loader, SmbFileUploader },
  mixins: [uploader],
  data: () => ({
    indexesListOfBusinessAssetsForEdit: [],
    isLoading: false,
    currentBrandingItem: null,
    removeBgDialog: {
      isShow: false,
      title: "Would you like to remove the background?",
      isLoading: false,
      isShowImgWithoutBg: false,
      footerButtons: [
        { name: "No", emitName: "cancelToRemoveBg", btnClass: "black-border" },
        { name: "Yes", emitName: "successToRemoveBg", btnClass: "black" },
      ],
    },
    imgUrlWithoutBg: null,
    objectForEdit: {
      value: null,
      index: null,
      type: null,
    },
  }),
  created() {
    if (this.$route.params && this.$route.params.currentBrandingForEdit) {
      this.currentBrandingItem =
        this.$route.params.currentBrandingForEdit === "brandLogo"
          ? "logo"
          : this.$route.params.currentBrandingForEdit === "brokerageLogo"
          ? "brokerage"
          : "headshot";
    }
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    ...mapActions("removeBg", ["deleteBgForImageByUrl"]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    emitClickOnUploaderByElementId(id) {
      setTimeout(() => {
        document.getElementById(id).click();
      }, 0);
    },
    async deleteBusinessAssetHandler(indexForDelete) {
      this.isLoading = true;
      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: this.getCurrentSmbCustomer.business_assets.filter(
            (businessAsset, businessAssetIndex) =>
              businessAssetIndex !== indexForDelete
          ),
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoading = false;
    },
    async deleteBrandingHandler(type) {
      this.isLoading = true;
      const paramName =
        type === "logo"
          ? "brand_logo"
          : type === "brokerage"
          ? "brokerage_logo"
          : type === "headshots"
          ? "brand_headshots"
          : "";

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          [paramName]: type === "headshots" ? [] : null,
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoading = false;
    },
    async editBusinessAsset(file, index) {
      this.isLoading = true;

      const { url, fields } = await this.uploadFileHandler(
        [...[file]],
        this.getCurrentSmbCustomer.id
      );

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: this.getCurrentSmbCustomer.business_assets.map(
            (businessAsset, businessAssetIndex) =>
              businessAssetIndex === index ? url + fields.key : businessAsset
          ),
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);

      this.isLoading = false;
    },
    async uploadNewBusinessFileHandler(file) {
      this.isLoading = true;

      const { url, fields } = await this.uploadFileHandler(
        [...[file]],
        this.getCurrentSmbCustomer.id
      );

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: [
            ...this.getCurrentSmbCustomer.business_assets,
            ...[url + fields.key],
          ],
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoading = false;
    },
    clickedOnEditIcon(value, valueIndexInList, type) {
      this.objectForEdit = {
        value: value,
        index: valueIndexInList,
        type: type,
      };

      if (value) {
        this.removeBgDialog.isShow = true;
      } else {
        const uploaderIdForEmitClickOn =
          type === "businessAsset"
            ? `businessAsset-${valueIndexInList}`
            : "branding-uploader";
        this.removeBgDialog.isShow = false;

        this.emitClickOnUploaderByElementId(uploaderIdForEmitClickOn);
      }
    },
    cancelToRemoveBgHandler() {
      const { type, index } = this.objectForEdit;
      const uploaderIdForEmitClickOn =
        type === "businessAsset"
          ? `businessAsset-${index}`
          : "branding-uploader";
      this.removeBgDialog.isShow = false;
      this.emitClickOnUploaderByElementId(uploaderIdForEmitClickOn);
    },
    async uploadBrandingItem(file) {
      this.isLoading = true;
      const { type } = this.objectForEdit;
      const { url, fields } = await this.uploadFileHandler(
        [...[file]],
        this.getCurrentSmbCustomer.id
      );

      const paramName =
        type === "logo"
          ? "brand_logo"
          : type === "brokerage"
          ? "brokerage_logo"
          : type === "headshots"
          ? "brand_headshots"
          : "";

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          [paramName]:
            type === "headshots" ? [url + fields.key] : url + fields.key,
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);

      this.objectForEdit = {
        value: null,
        index: null,
        type: null,
      };

      this.isLoading = false;
    },
    async approveToRemoveBg() {
      this.removeBgDialog.isLoading = true;
      const { type, value, index } = this.objectForEdit;

      if (value) {
        try {
          const { image_url, error } = await this.deleteBgForImageByUrl(value);

          if (!error) {
            this.imgUrlWithoutBg = image_url;
            this.removeBgDialog.isShowImgWithoutBg = true;

            this.removeBgDialog = {
              ...this.removeBgDialog,
              ...{
                title: `Set this image as your new ${
                  type === "logo"
                    ? "logo"
                    : type === "brokerage"
                    ? "brokerage"
                    : type === "businessAsset"
                    ? "business asset"
                    : ""
                }?`,
                footerButtons: [
                  {
                    name: "No",
                    emitName: "cancelToSetImgWithoutBg",
                    btnClass: "black-border",
                  },
                  {
                    name: "Yes",
                    emitName: "acceptToSetImgWithoutBg",
                    btnClass: "black",
                  },
                ],
              },
            };

            this.removeBgDialog.isLoading = false;
          } else {
            this.somethingWentWrongWhenDeletedBackgroundHandler();
          }
        } catch (e) {
          console.log(e);
          this.somethingWentWrongWhenDeletedBackgroundHandler();
        }
      }
    },
    somethingWentWrongWhenDeletedBackgroundHandler() {
      this.removeBgDialog = {
        ...this.removeBgDialog,
        ...{
          title: "Something went wrong...",
          footerButtons: [
            { name: "Ok", emitName: "cancel", btnClass: "black" },
          ],
          isShowImgWithoutBg: false,
        },
      };
      this.removeBgDialog.isLoading = false;
    },
    cancelToSetImgWithoutBgHandler() {
      this.makeDefaultDialogOptions();
    },
    makeDefaultDialogOptions() {
      this.removeBgDialog = {
        isShow: false,
        title: "Would you like to remove the background?",
        isLoading: false,
        isShowImgWithoutBg: false,
        footerButtons: [
          {
            name: "No",
            emitName: "cancelToRemoveBg",
            btnClass: "black-border",
          },
          { name: "Yes", emitName: "successToRemoveBg", btnClass: "black" },
        ],
      };
    },
    async acceptToSetImgWithoutBgHandler() {
      this.removeBgDialog.isShow = false;
      this.isLoading = true;

      const { filename, extension } = this.extractFilenameAndType(
        this.imgUrlWithoutBg
      );

      const {
        data: { media_url },
      } = await this.uploadMediaFromUrl({
        customerId: this.getCurrentSmbCustomer.id,
        inputMediaUrl: this.imgUrlWithoutBg,
        createdBy: "6",
        isLicensed: false,
        processMedia: false,
        createMediaItem: false,
        fileName: `${filename}_bg.${extension}`,
      });

      const { type, index } = this.objectForEdit;
      const paramName =
        type === "logo"
          ? "brand_logo"
          : type === "brokerage"
          ? "brokerage_logo"
          : type === "headshots"
          ? "brand_headshots"
          : type === "businessAsset"
          ? "business_assets"
          : "";

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
      };

      if (type === "businessAsset") {
        params = {
          ...params,
          params: {
            [paramName]: this.getCurrentSmbCustomer.business_assets.map(
              (businessAsset, businessAssetIndex) => {
                if (businessAssetIndex === index) {
                  businessAsset = media_url;
                }
                return businessAsset;
              }
            ),
          },
        };
      } else {
        params = {
          ...params,
          params: {
            [paramName]: type === "headshots" ? [media_url] : media_url,
          },
        };
      }
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.makeDefaultDialogOptions();

      this.isLoading = false;
    },
    extractFilenameAndType(url) {
      const parts = url.split("/");
      const filenameWithExtension = parts[parts.length - 1];

      const filenameParts = filenameWithExtension.split(".");
      const filename = filenameParts.slice(0, -1).join(".");
      const extension = filenameParts[filenameParts.length - 1];

      return { filename, extension };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  padding-left: 0;
}
.branding-list-wrapper {
  padding: 21px 20px 10px 20px;
  width: 100%;
  max-width: 600px;
  .small-img-container {
    aspect-ratio: 1 /1;
    position: relative;
    .business-asset-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    .icon-remove-png {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 17px;
      height: 17px;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .icon-edit-png {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 17px;
      height: 17px;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
.business-assets-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 120px));
  grid-gap: 20px;
  margin-top: 10px;
  &__item {
    aspect-ratio: 1 /1;
    border: 1px solid #cccc;
    display: flex;
    .small-img-container {
      aspect-ratio: 1 /1;
      position: relative;
      background: #8e9194;
      .business-asset-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
      .icon-remove-png {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 17px;
        height: 17px;
        display: inline-flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .icon-edit-png {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 17px;
        height: 17px;
        display: inline-flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
  @media screen and (max-width: 760px) {
    padding-bottom: 20px;
  }
}
.branding-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 120px));
  grid-gap: 20px;
  border-radius: 20px;
  &__icon {
    img {
      width: 18px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    &-img {
      aspect-ratio: 1 /1;
      border: 1px solid #cccc;
      display: flex;
      position: relative;
      background: #8e9194;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        aspect-ratio: 1 / 1;
      }
      .edit-media {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        height: auto;
        width: auto;
        min-width: auto;
        padding: 0;
        letter-spacing: initial;
        text-transform: none;
        border: 1px solid #1d1d1b;
        border-radius: 5px;
        transition: all 0.3s ease;
        display: none;

        ::v-deep {
          .v-btn__content {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px;
            min-width: 80px;
          }
        }

        &__icon {
          display: inline-flex;

          img {
            width: 18px;
          }

          .main-icon {
            display: block;
          }

          .hover-icon {
            display: none;
          }
        }

        &__text {
          color: #1d1d1b;
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          margin-top: 2px;
        }

        &:hover {
          background: #92c4ff;

          .edit-media {
            &__icon {
              .main-icon {
                position: relative;
                left: 1px;
                display: none;
              }

              .hover-icon {
                display: block;
              }
            }
          }
        }
      }
      &:hover {
        .edit-media {
          display: flex;
        }
      }
    }
    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
    }
  }
  .active {
    border: 2px solid darkred;
  }
}
.asset-uploader {
  display: none;
}
.uploader {
  border: 1px dashed black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 50px;
  font-weight: 200;
}
.business-assets__title {
  padding-top: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
.footer-buttons {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  letter-spacing: 0;
  position: relative;
  @media screen and (min-width: 760px) {
    margin-top: 50px;
    max-width: 500px;
    padding: 0 20px 10px 20px;
    justify-content: flex-end;
  }

  .button-item {
    width: 100%;
    @media screen and (min-width: 760px) {
      width: 313px;
    }
  }
  .arrow-button {
    width: 20%;
    position: absolute;
    left: 0;
    z-index: 10;
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
}

.uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  background: #8e9194;
  &__text {
    text-decoration: underline;
  }
  &.border {
    border: 1px solid #cccc;
  }
}
</style>
