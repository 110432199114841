<template>
  <v-file-input
    v-model="propModel"
    :id="elementId"
    :class="customClass"
    color="deep-purple accent-4"
    counter
    :ref="elementRef"
    :label="label"
    :accept="acceptProp"
    :multiple="isMultiple"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :hide-details="hideDetails"
    background-color="#fff"
    outlined
    :show-size="1000"
    :hide-input="isHideInput"
    @cancel="handleFileCancel"
    @click="clickHandler"
  >
    <template v-slot:selection="{ index, text }">
      <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="text-overline grey--text text--darken-3 mx-2"
      >
        +{{ filesProp.length - 2 }} File(s)
      </span>
    </template>
  </v-file-input>
</template>

<script>
export default {
  name: "SmbFileUploader",
  data: () => ({
    files: null,
  }),
  props: {
    filesProp: {
      type: [File, Array, String],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    acceptProp: {
      type: String,
      default: "",
    },
    isHideInput: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    elementId: {
      type: String,
      default: "uploader" + new Date(),
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    elementRef: {
      type: String,
      default: "uploader" + new Date(),
    },
  },
  computed: {
    propModel: {
      get() {
        return this.filesProp;
      },
      set(value) {
        this.$emit("filesWasUpdated", value);
      },
    },
  },
  methods: {
    handleFileCancel() {
      this.$emit("dialogCanceledWithoutSelectingFiles");
    },
    clickHandler() {
      this.$emit("clickedOnFileInput");
    },
  },
};
</script>

<style scoped lang="scss">
.smb-uploader {
  ::v-deep {
    .v-input__prepend-outer {
      display: none;
    }
    .v-label--active {
      display: none;
    }
    label {
      color: #000000;
      font-weight: 500;
      font-size: 15px;
    }
    fieldset {
      border-color: #000000 !important;
      legend {
        width: 0px !important;
      }
    }
  }
}
</style>
